/**
 * The version key.
 */
export const VersionKeys = {
    V0_0_0: "V0.0.0",
    V1_0_0: "V1.0.0",
    V1_0_1: "V1.0.1",
    V1_0_2: "V1.0.2",
    V1_0_3: "V1.0.3",
    V1_0_4: "V1.0.4",
    V1_1_0: "V1.1.0",
    V1_1_1: "V1.1.1",
    V1_1_2: "V1.1.2",
    V1_1_3: "V1.1.3",
    V1_1_4: "V1.1.4",
    V1_1_5: "V1.1.5",
    V1_2_0: "V1.2.0",
    V1_3_0: "V1.3.0",
    V1_4_0: "V1.4.0",
    V1_5_0: "V1.5.0",
    V1_5_1: "V1.5.1",
    V1_6_0: "V1.6.0",
    V1_6_1: "V1.6.1",
    V1_7_0: "V1.7.0",
    V1_8_0: "V1.8.0",
    V1_9_0: "V1.9.0",
    V1_10_0: "V1.10.0",
    V1_10_1: "V1.10.1",
    V1_11_0: "V1.11.0",
    V1_11_1: "V1.11.1",
    V1_12_0: "V1.12.0",
    V1_13_0: "V1.13.0",
    V1_13_1: "V1.13.1",
    V1_14_0: "V1.14.0",
    V1_15_0: "V1.15.0",
    V1_16_0: "V1.16.0",
    V1_17_0: "V1.17.0",
    V1_18_0: "V1.18.0",
    V1_19_0: "V1.19.0",
    V1_20_0: "V1.20.0",
    V1_21_0: "V1.21.0",
    V2_0_0: "V2.0.0",
    V2_1_0: "V2.1.0",
    V2_2_0: "V2.2.0",
    V2_3_0: "V2.3.0",
    V2_4_0: "V2.4.0",
    V2_5_0: "V2.5.0",
    V2_6_0: "V2.6.0",
    V2_7_0: "V2.7.0",
    V2_8_0: "V2.8.0",
    V2_9_0: "V2.9.0",
    V2_10_0: "V2.10.0",
    V2_11_0: "V2.11.0",
}

/**
 * The version structure.
 */
export const Versions = {
    [VersionKeys.V0_0_0]: {
        emptyState: false,
        next: VersionKeys.V1_0_0,
    },
    [VersionKeys.V1_0_0]: {
        emptyState: true,
        next: VersionKeys.V1_0_1,
    },
    [VersionKeys.V1_0_1]: {
        emptyState: true,
        next: VersionKeys.V1_0_2,
    },
    [VersionKeys.V1_0_2]: {
        emptyState: false,
        next: VersionKeys.V1_0_3,
    },
    [VersionKeys.V1_0_3]: {
        emptyState: false,
        next: VersionKeys.V1_0_4,
    },
    [VersionKeys.V1_0_4]: {
        emptyState: false,
        next: VersionKeys.V1_1_0,
    },
    [VersionKeys.V1_1_0]: {
        emptyState: false,
        next: VersionKeys.V1_1_1,
    },
    [VersionKeys.V1_1_1]: {
        emptyState: true,
        next: VersionKeys.V1_1_2,
    },
    [VersionKeys.V1_1_2]: {
        emptyState: true,
        next: VersionKeys.V1_1_3,
    },
    [VersionKeys.V1_1_3]: {
        emptyState: true,
        next: VersionKeys.V1_1_4,
    },
    [VersionKeys.V1_1_4]: {
        emptyState: true,
        next: VersionKeys.V1_1_5,
    },
    [VersionKeys.V1_1_5]: {
        emptyState: true,
        next: VersionKeys.V1_2_0,
    },
    [VersionKeys.V1_2_0]: {
        emptyState: false,
        next: VersionKeys.V1_3_0,
    },
    [VersionKeys.V1_3_0]: {
        emptyState: false,
        next: VersionKeys.V1_4_0,
    },
    [VersionKeys.V1_4_0]: {
        emptyState: false,
        next: VersionKeys.V1_5_0,
    },
    [VersionKeys.V1_5_0]: {
        emptyState: false,
        next: VersionKeys.V1_5_1,
    },
    [VersionKeys.V1_5_1]: {
        emptyState: false,
        next: VersionKeys.V1_6_0,
    },
    [VersionKeys.V1_6_0]: {
        emptyState: false,
        next: VersionKeys.V1_6_1,
    },
    [VersionKeys.V1_6_1]: {
        emptyState: false,
        next: VersionKeys.V1_7_0,
    },
    [VersionKeys.V1_7_0]: {
        emptyState: false,
        next: VersionKeys.V1_8_0,
    },
    [VersionKeys.V1_8_0]: {
        emptyState: false,
        next: VersionKeys.V1_9_0,
    },
    [VersionKeys.V1_9_0]: {
        emptyState: false,
        next: VersionKeys.V1_10_0,
    },
    [VersionKeys.V1_10_0]: {
        emptyState: false,
        next: VersionKeys.V1_10_1,
    },
    [VersionKeys.V1_10_1]: {
        emptyState: false,
        next: VersionKeys.V1_11_0,
    },
    [VersionKeys.V1_11_0]: {
        emptyState: false,
        next: VersionKeys.V1_11_1,
    },
    [VersionKeys.V1_11_1]: {
        emptyState: false,
        next: VersionKeys.V1_12_0,
    },
    [VersionKeys.V1_12_0]: {
        emptyState: false,
        next: VersionKeys.V1_13_0,
    },
    [VersionKeys.V1_13_0]: {
        emptyState: false,
        next: VersionKeys.V1_13_1,
    },
    [VersionKeys.V1_13_1]: {
        emptyState: false,
        next: VersionKeys.V1_14_0,
    },
    [VersionKeys.V1_14_0]: {
        emptyState: false,
        next: VersionKeys.V1_15_0,
    },
    [VersionKeys.V1_15_0]: {
        emptyState: false,
        next: VersionKeys.V1_16_0,
    },
    [VersionKeys.V1_16_0]: {
        emptyState: false,
        next: VersionKeys.V1_17_0,
    },
    [VersionKeys.V1_17_0]: {
        emptyState: false,
        next: VersionKeys.V1_18_0,
    },
    [VersionKeys.V1_18_0]: {
        emptyState: false,
        next: VersionKeys.V1_19_0,
    },
    [VersionKeys.V1_19_0]: {
        emptyState: false,
        next: VersionKeys.V1_20_0,
    },
    [VersionKeys.V1_20_0]: {
        emptyState: false,
        next: VersionKeys.V1_21_0,
    },
    [VersionKeys.V1_21_0]: {
        emptyState: false,
        next: VersionKeys.V2_0_0,
    },
    [VersionKeys.V2_0_0]: {
        emptyState: false,
        next: VersionKeys.V2_1_0,
    },
    [VersionKeys.V2_1_0]: {
        emptyState: false,
        next: VersionKeys.V2_2_0,
    },
    [VersionKeys.V2_2_0]: {
        emptyState: true,
        next: VersionKeys.V2_3_0,
    },
    [VersionKeys.V2_3_0]: {
        emptyState: false,
        next: VersionKeys.V2_4_0,
    },
    [VersionKeys.V2_4_0]: {
        emptyState: false,
        next: VersionKeys.V2_5_0,
    },
    [VersionKeys.V2_5_0]: {
        emptyState: false,
        next: VersionKeys.V2_6_0,
    },
    [VersionKeys.V2_6_0]: {
        emptyState: false,
        next: VersionKeys.V2_7_0,
    },
    [VersionKeys.V2_7_0]: {
        emptyState: false,
        next: VersionKeys.V2_8_0,
    },
    [VersionKeys.V2_8_0]: {
        emptyState: false,
        next: VersionKeys.V2_9_0,
    },
    [VersionKeys.V2_9_0]: {
        emptyState: false,
        next: VersionKeys.V2_10_0,
    },
    [VersionKeys.V2_10_0]: {
        emptyState: false,
        next: VersionKeys.V2_11_0,
    },
    [VersionKeys.V2_11_0]: {
        emptyState: false,
    },
}
